export default [
  {
    id: 1,
    city: "Sogdiana",
    color: "gray",
    coordinates: [39.0, 17.5],
    value: 30,
    description: "Builder: Various",
    text: "Sogdiana (has) seven abodes, its having seven abodes in it means that there were seven Lords in it. One belonging to Jam and one to Aži Dahāg and one to Frēdōn and one to Manūčihr and one to Kāūs and one to Lohrāsp and one to king Wištāsp. Then the accursed Frāsiyāk, the Tūrānian, made seats for each of the demons, and an idol temple and a heathen temple.",
  },

  {
    id: 2,
    city: "Samarkand",
    color: "gray",
    coordinates: [38.0, 19.5],
    value: 10,
    description: "Builder: Kāūs",
    text: "In the Eastern direction, the foundation of the city of Samarkand was laid by Kāūs, the son of Kawād. Sīyāwaxš, the son of Kāūs completed it. Kay Husraw, the son of Sīyāwaxš was born there, and he set the miraculous Wahrām fire there. Then Zoroaster brought the Religion. By the order of king Wištāsp 1200 fragard (chapters) in the script of religious scripture were engraved on golden tablets and written and deposited in the treasury of that fire-(temple). And then the accursed Alexander burnt and threw it into the sea. ",
  },

  {
    id: 5,
    city: "Xwārazm",
    color: "gray",
    coordinates: [41.8, 9.0],
    value: 10,
    description: "Builder: Narsēh",
    text: "The city of Xwārazm was built by Narsēh, the son of the Jewess. ",
  },

  {
    id: 6,
    city: "Marv-rōd",
    color: "gray",
    coordinates: [32.5, 13.3],
    value: 10,
    description: "Builder: Wahrām",
    text: "The city of Marv-rōd was built by Wahrām, the son of Yazdgerd.  ",
  },

  {
    id: 7,
    city: "Marv",
    color: "gray",
    coordinates: [34.8, 11.0],
    value: 10,
    description: "Builder: Alexander",
    text: "The city of Marv and the city of Herāt were built by the accursed Alexander the Roman.  ",
  },

  {
    id: 8,
    city: "Herāt",
    color: "gray",
    coordinates: [30.5, 10.7],
    value: 10,
    description: "Builder: Alexander",
    text: "The city of Marv and the city of Herāt were built by the accursed Alexander the Roman. ",
  },

  {
    id: 9,
    city: "Pōšang",
    color: "gray",
    coordinates: [29.5, 9.3],
    value: 10,
    description: "Builder: Šābuhr",
    text: "The city of Pōšang was built by Šābuhr the son of Ardaxšīr, and he built a large bridge in Pōšang.  ",
  },

  {
    id: 3,
    city: "Tūs",
    color: "gray",
    coordinates: [33.5, 7.6],
    value: 10,
    description: "Builder: Tūs",
    text: "The city of Tūs was built by Tūs, the son of Nōdar, and (he) was the general for 900 years. After Tūs, the office of general came to Zarēr and from Zarēr to Bastūr and from Bastūr to Karzam. ",
  },

  {
    id: 11,
    city: "Nēwšābuhr",
    color: "gray",
    coordinates: [32.5, 5.6],
    value: 10,
    description: "Builder: Šābuhr",
    text: "The city of Nēwšābuhr was built by Šābuhr the son of Ardaxšīr. At that time when he killed the Tūrānian Pahlīzag, he ordered to build the city in the same place.  ",
  },

  {
    id: 12,
    city: "Qāyēn",
    color: "gray",
    coordinates: [29.0, 5.2],
    value: 10,
    description: "Builder: Kay Lohrāsp",
    text: "The city of Qāyēn was built by Kay Lohrāsp, the father of Wištāsp. ",
  },

  {
    id: 13,
    city: "Dahestān",
    color: "gray",
    coordinates: [35.8, 0.2],
    value: 10,
    description: "Builder: Narseh",
    text: "In Gurgān, the city which they call Dahestān was built by Narseh, the Arsacid. ",
  },

  {
    id: 14,
    city: "Kūmīs",
    color: "gray",
    coordinates: [31.5, -1.5],
    value: 10,
    description: "Builder: Aži Dahāg",
    text: "The city of Kūmīs of five towers Aži Dahāg made it his own harem. The abode of the *Parthians was there. In the reign of Yazdgird, the son of Šābuhr made it during the invasion of the Čōl, at the boundary of the Gruznian Guard.  ",
  },

  {
    id: 15,
    city: "Husraw-šād",
    color: "gray",
    coordinates: [33.5, 0.0],
    value: 10,
    description: "Builder: Husraw",
    text: "Five cities were built by Husraw, the son of Kawād: Husraw-šād, and Husraw ī Mūst-Ābād, and Wisp-šād-Husraw and Hū-bōy-Husraw, and Šād-farrox-Husraw and named them. ",
  },

  {
    id: 22,
    city: "Ctesiphon",
    color: "gray",
    coordinates: [28.0, -16.3],
    value: 10,
    description: "Builder: Tūs",
    text: "In the Western direction, the city of Ctesiphon was built by the order of Tūs, the son of Warāz from the family of Gēw. ",
  },

  {
    id: 23,
    city: "Nisibis",
    color: "gray",
    coordinates: [33.0, -19.5],
    value: 10,
    description: "Builder: Warāzag",
    text: "The city of Nisibis was built by Warāzag the son of Gēw.  ",
  },

  {
    id: 24,
    city: "Edessa",
    color: "gray",
    coordinates: [33.0, -23.0],
    value: 10,
    description: "Builder: Narseh",
    text: "The city of Edessa was built by Narseh, the Arsacid. ",
  },

  {
    id: 25,
    city: "Babylon",
    color: "gray",
    coordinates: [27.0, -16.0],
    value: 10,
    description: "Builder: Bābēl",
    text: "The city of Babylon, was built by Bābēl during the reign of Jam. And he bound the planet Mercury there and he showed the seven planets and the twelve constellations and the eighth portions by sorcery to the Sun and to those below (mankind).  ",
  },

  {
    id: 26,
    city: "Hīra",
    color: "gray",
    coordinates: [26.0, -15.5],
    value: 10,
    description: "Builder: Šābuhr",
    text: "The city of Hīra was built by Šābuhr, the son of Ardaxšīr and he appointed Mihrzād the margrave of Hīra over the wall of the Arabs.  ",
  },

  {
    id: 27,
    city: "Hamadān",
    color: "gray",
    coordinates: [30.5, -9.5],
    value: 10,
    description: "Builder: Yazdgird",
    text: "The city of Hamadān was built by Yazdgird, the son of Šābuhr whom they call Yazdgird the rough.  ",
  },

  {
    id: 10,
    city: "Mosul",
    color: "gray",
    coordinates: [32.0, -17.0],
    value: 10,
    description: "Builder: Pērōz",
    text: "The city of Mosul was built by Pērōz, the son of Šābuhr ",
  },

  {
    id: 34,
    city: "Kābul",
    color: "gray",
    coordinates: [30, 23],
    value: 10,
    description: "Builder: Ardaxšīr",
    text: "In the Southern direction, the city of Kābul was built by Ardaxšīr, the son of Spandyād. ",
  },

  {
    id: 36,
    city: "Būst",
    color: "gray",
    coordinates: [25.8, 13.5],
    value: 10,
    description: "Builder: Bastūr",
    text: "The city of Būst was built by Bastūr, the son of Zarēr, at that time when king Wištāsp worshipped the religion by the Frazdān (lake), and the abode of Wištāsp and other blood princes was established. ",
  },

  {
    id: 37,
    city: "Frāh",
    color: "gray",
    coordinates: [27.2, 10.2],
    value: 10,
    description: "Builder: Rustam",
    text: "The city of Frāh and the city of Zābul were built by Rustam, the king of Sīstān. ",
  },

  {
    id: 38,
    city: "Zābul",
    color: "gray",
    coordinates: [25.2, 8.5],
    value: 10,
    description: "Builder: Rustam",
    text: "The city of Frāh and the city of Zābul were built by Rustam, the king of Sīstān. ",
  },

  {
    id: 39,
    city: "Zarang",
    color: "gray",
    coordinates: [24.5, 8.5],
    value: 10,
    description: "Builder: Frāsiyāk",
    text: "The city of Zarang was first built by the accursed Frāsiyāk, the Tūranian, and established the miraculous Karkōy Fire there, and Manūčihr was (surrounded) in Padišxwargar, and (Frāsiyāk) asked Spandarmad as wife and Spandarmad mixed in the earth, (he) destroyed the city and he extinguished the fire, and then Kay Husraw, the son of Siyāwaxš again built the city. And he again founded the Karkōy Fire, and Ardaxšīr, the son of Pābag finished the city.  ",
  },

  {
    id: 40,
    city: "Kermān",
    color: "gray",
    coordinates: [25.0, 2.0],
    value: 10,
    description: "Builder: Kawād",
    text: "The city of Kermān was built by (*Kawād) the son Pērōz, the king of Kermān. ",
  },

  {
    id: 41,
    city: "Weh-Ardaxšīr",
    color: "gray",
    coordinates: [24.0, 1.2],
    value: 10,
    description: "Builder: three lords",
    text: "The city of Weh-Ardaxšīr was built by three lords and Ardaxšīr, the son of Pābag finished it. ",
  },

  {
    id: 42,
    city: "Staxr",
    color: "gray",
    coordinates: [23.2, -5.2],
    value: 10,
    description: "Builder: Ardawān",
    text: "The city of Staxr was built by Ardawān, king of the *Parthians. ",
  },

  {
    id: 43,
    city: "Dārābgird",
    color: "gray",
    coordinates: [22.0, -3.4],
    value: 10,
    description: "Builder: Dārā",
    text: "The city of Dārābgird was built by Dārā, the son of Dārā ",
  },

  {
    id: 44,
    city: "Bēšābuhr",
    color: "gray",
    coordinates: [22.8, -7.5],
    value: 10,
    description: "Builder: Šābuhr",
    text: "The city of Bēšābuhr was built by Šābuhr, the son of Ardaxšīr. ",
  },

  {
    id: 45,
    city: "Gōr-Ardaxšīr-Xwarrah",
    color: "gray",
    coordinates: [21.8, -6.4],
    value: 10,
    description: "Builder: Ardaxšīr",
    text: "The city of Gōr-Ardaxšīr-Xwarrah was built by Ardaxšīr, the son of Pābag. ",
  },

  {
    id: 46,
    city: "Tūz",
    color: "gray",
    coordinates: [17.5, 7.2],
    value: 10,
    description: "Builder: Humāy",
    text: "The city of Tūz was built by Humāy, the son of Čihr Āzād. ",
  },

  {
    id: 47,
    city: "Hormizd-Ardaxšīr",
    color: "gray",
    coordinates: [25.0, -10.7],
    value: 10,
    description: "Builder: Rām-Hormizd",
    text: "The city of Hormizd-Ardaxšīr and the city of Rām-Hormizd were built by Hormizd, the valiant, the son of Šābuhr ",
  },

  {
    id: 48,
    city: "Susa and Šūštar",
    color: "gray",
    coordinates: [26.2, -10.7],
    value: 10,
    description: "Builder: Šīšīnduxt",
    text: "The city of Susa and Šūštar were built by Šīšīnduxt, the wife of Yazdgird, the son of Šābuhr, since she was the daughter of Reš Galut, the king of the Jews and also was the mother of Wahrām Gōr. ",
  },
];
