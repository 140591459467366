import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactGlobe from "react-globe";
import markerRenderer from "./markerRenderer.ts";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

import defaultMarkers from "./markers";

function markerTooltipRenderer(marker) {
  let text = [
    <h2> {marker.city}:</h2>,
    <br />,

    <b>{marker.description}</b>,
    <br />,
    <br />,
    `${marker.text}`,
    <br />,
    <br />,
    <br />,
    <br />,
    "7th century text:",
    <br />,
    "Shahristaha-i-Eranshahr",
    <br />,
    "Translated by Touraj Daryaee",
  ];
  return text;
}

const options = {
  markerRenderer,
  markerTooltipRenderer: (marker) => JSON.stringify(marker.city),
  cameraAutoRotateSpeed: 0.01,
  ambientLightIntensity: 0.3,
  globeCloudsOpacity: 0.8,
  enableMarkerGlow: true,
  markerRadiusScaleRange: [0.005, 0.02],
  markerType: "dot",
};

function App() {
  const [markers, setMarkers] = useState([]);
  const [event, setEvent] = useState(null);
  const [details, setDetails] = useState(null);

  // const globeBackgroundTexture =
  //   "https://raw.githubusercontent.com/chrisrzhou/react-globe/main/textures/invalid_background_file.png";
  const globeCloudsTexture = "https://i.imgur.com/8yc7TqO.png";

  const globeTexture = "https://maps.eranshahr.com/img/globetexture.jpg";
  // const globeTexture = "https://i.imgur.com/h3pS7eG.jpeg";
  // const globeTexture = "https://i.imgur.com/IGDOfBF.jpeg";

  function onMouseOverMarker(marker, markerObject, event) {
    setEvent({
      type: "MOUSEOVER",
      marker,
      markerObjectID: markerObject.uuid,
      pointerEventPosition: { x: event.clientX, y: event.clientY },
    });
  }

  function onClickMarker(marker, markerObject, event) {
    setEvent({
      type: "CLICK",
      marker,
      markerObjectID: markerObject.uuid,
      pointerEventPosition: { x: event.clientX, y: event.clientY },
    });
    setDetails(markerTooltipRenderer(marker));
  }
  function onDefocus(previousFocus) {
    setEvent({
      type: "DEFOCUS",
      previousFocus,
    });
    setDetails(null);
  }

  return (
    <div>
      {details && (
        <div
          style={{
            // background: "yellow",
            fontFamily: "Ubuntu",
            color: "white",
            position: "absolute",
            width: 350,
            fontSize: 16,
            bottom: 0,
            right: 0,
            padding: 30,
          }}
        >
          <div>{details}</div>
          {/* <p>
            EVENT: type={event.type}, position=
            {JSON.stringify(event.pointerEventPosition)})
          </p> */}
        </div>
      )}

      <ReactGlobe
        // globeBackgroundTexture={globeBackgroundTexture}
        globeCloudsTexture={globeCloudsTexture}
        globeTexture={globeTexture}
        height="100vh"
        markers={defaultMarkers}
        options={options}
        width="100vw"
        onClickMarker={onClickMarker}
        onMouseOverMarker={onMouseOverMarker}
        onDefocus={onDefocus}
        // initialCoordinates={[29.726118, -6.327458]}
      />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
